import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import back from "../../assets/back.png";
import axios from "../api/axios";
import Sidebars from "../sidebars/sidebars";
import "./AltaProveedores.css";

const EditProvider = () => {
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFToken";
  axios.defaults.withCredentials = true;

  const [buttonDisable, setButttonDisable] = useState(false);

  const { cuil } = useParams();
  const { state } = useLocation();

  const [name, setName] = useState(state.name !== null ? state.name : "");
  const [email, setEmail] = useState(state.email !== null ? state.email : "");
  const [cbu, setCbu] = useState(state.cbu !== null ? state.cbu : "");
  const [cbuUSD, setCbuUSD] = useState(
    state.cbu_usd !== null ? state.cbu_usd : ""
  );

  const [usertype, setUsertype] = useState({
    label: state.usertype !== null ? state.usertype : "",
    value: state.usertype !== null ? state.usertype : "",
  });
  const [area, setArea] = useState({
    label: state.area !== null ? state.area : "",
    value: state.area !== null ? state.area : "",
  });
  const [estado, setEstado] = useState({
    label: state.state === "1" ? "ACTIVO" : "INACTIVO",
    value: state.state,
  });

  const options = [
    { label: "FYO", value: "FYO" },
    { label: "GRANOS", value: "Granos" },
    { label: "GENERAL", value: "General" },
    { label: "VALORES", value: "Valores" },
  ];

  const options2 = [
    { label: "HABITUAL", value: "Habitual" },
    { label: "EVENTUAL", value: "Eventual" },
  ];

  const options3 = [
    { label: "ACTIVO", value: "1" },
    { label: "INACTIVO", value: "0" },
  ];

  const handleChangeCBU = (event) => {
    if (/^[0-9]{0,22}$/.test(event.target.value)) {
      setCbu(event.target.value);
    }
  };

  const handleChangeCBUUSB = (event) => {
    if (/^[0-9]{0,22}$/.test(event.target.value)) {
      setCbuUSD(event.target.value);
    }
  };

  const PutProvider = async (e) => {
    e.preventDefault();

    setButttonDisable(true);

    const toastId = toast.loading("actualizando proveedor...");

    if (!(cbu.trim() === "")) {
      if (cbu.length !== 22) {
        toast.dismiss(toastId);
        toast.error("Error: CBU debe tener de 22 número", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          closeButton: true,
          progress: undefined,
          theme: "colored",
        });
        setButttonDisable(false);

        return;
      }
    }

    const optionsProv = {
      method: "PUT",
      url: "proveedores/edit/" + cuil,
      data: {
        cbu: cbu.trim() !== "" ? cbu : null,
        cbu_usd: cbuUSD.trim() !== "" ? cbuUSD : null,
        is_representative: false,
        name: name,
        email: email,
        cuil: cuil,
        area: area.value,
        state: estado.value,
        usertype: usertype.value,
      },
    };

    await axios
      .request(optionsProv)
      .then((response) => {
        if (response.status === 201) {
          toast.dismiss(toastId);
          toast.success("Proveedor Actualizado", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setButttonDisable(false);
        }
        toast.dismiss(toastId);
      })
      .catch((error) => {
        toast.dismiss(toastId);
        setButttonDisable(false);

        if (error.response.status === 404) {
          toast.error("Error: No existe el proveedor con cuil: " + cuil);
        } else if (error.response.status === 401) {
          toast.error("Error: Usuario no logueado");
        } else if (error.response.status === 400) {
          error.response.data.forEach((value, key) => {
            value?.array.forEach((element) => {
              toast.error(key + ": " + element);
            });
          });
        } else {
          toast.error("Error: No se actualizó proveedor");
        }
      });
  };

  return (
    <div>
      <Sidebars></Sidebars>

      <div className="divform column">
        <h3
          className="titleform"
          style={{
            "margin-top": "1%",
          }}
        >
          <Link to={"/proveedores"}>
            <button type="button" class="btn btn-light">
              <img src={back} alt="volver" height={30} width={30}></img>
            </button>
          </Link>
        </h3>
        <br></br>
        <h2 className="titleform">Editar Proveedor con cuil: {cuil}</h2>
        <br></br>
        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">Razon Social:</label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div class="mb-3 rowform">
          <label className="col-sm-2 col-form-label">Email:</label>
          <div className="col-sm-10">
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">CBU:</label>
          <div className="col-sm-10">
            <input
              size="22"
              maxlength="22"
              type="text"
              className="form-control"
              value={cbu}
              onChange={handleChangeCBU}
            />
          </div>
        </div>

        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">CBU USD:</label>
          <div className="col-sm-10">
            <input
              size={22}
              maxlength={22}
              type="text"
              className="form-control"
              placeholder="(opcional)"
              value={cbuUSD}
              onChange={handleChangeCBUUSB}
              required
            />
          </div>
        </div>

        <div className="mb-3 rowform selectMobile">
          <label for="staticEmail" className="col-sm-2 col-form-label">
            Area:
          </label>
          <div className="col-sm-10 selectMobile">
            <Select
              className="form-control-plaintext"
              defaultValue={area}
              options={options}
              onChange={(e) => {
                setArea({
                  label: e.label,
                  value: e.value,
                });
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#0d9963",
                  primary: "#0d9963b5",
                },
              })}
            />
          </div>
        </div>

        <div className="mb-3 rowform selectMobile">
          <label for="staticEmail" className="col-sm-2 col-form-label">
            Tipo:
          </label>
          <div className="col-sm-10">
            <Select
              className="form-control-plaintext"
              defaultValue={usertype}
              options={options2}
              onChange={(e) => {
                setUsertype({
                  label: e.label,
                  value: e.value,
                });
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#0d9963",
                  primary: "#0d9963b5",
                },
              })}
            />
          </div>
        </div>
        <div className="mb-3 rowform selectMobile">
          <label for="staticEmail" className="col-sm-2 col-form-label">
            Estado:
          </label>
          <div className="col-sm-10">
            <Select
              className="form-control-plaintext"
              defaultValue={estado}
              options={options3}
              onChange={(e) => {
                setEstado({
                  label: e.label,
                  value: e.value,
                });
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#0d9963",
                  primary: "#0d9963b5",
                },
              })}
            />
          </div>
        </div>
        <div className="mb-3 rowform">
          <button
            disabled={buttonDisable}
            className={buttonDisable ? "button-9 act" : "button-9"}
            onClick={(e) => PutProvider(e)}
          >
            Actualizar
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProvider;

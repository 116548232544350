import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useEncryptedData } from "../../../utils/encryptedData";
import axios from "../../api/axios";
import FilterCalendar from "../../other/FilterCalendar";
import ModalTxt from "../../other/ModalTxt";
import ModalStates from "../../other/ModalStates";
import FilterSelect from "../../other/filterSelect";
import Search from "../../search/Search";
import Sidebars from "../../sidebars/sidebars";
import { DataTable } from "./DataTable";
import "./TableInvoices.css";
import { Row, Col } from "react-bootstrap";
import { contacts } from "../../../utils/listContacs";

axios.defaults.withCredentials = true;

export default function TableInvoices() {
  const ls = useEncryptedData();
  const userData = ls.get("token", { secret: process.env.REACT_APP_SECRET });

  const isFinanza = userData.user.rol.includes("Finanzas");
  const isContabilidad = userData.user.rol.includes("Conciliaciones");
  const isContact = contacts.includes(userData.user.email);

  const [invoices, setInvoices] = useState([]);
  const [invoicesFilter, setInvoiceFilter] = useState([]);
  const [DateDesde, setDateDesde] = useState(null);
  const [DateHasta, setDateHasta] = useState(null);
  const [checkedLBO, setCheckedLBO] = useState(true);
  const [checkedAgrofidi, setCheckedAgrofidi] = useState(true);
  const [isRepresentative, setIsRepresentative] = useState(false);

  const [url, setUrl] = useState(
    ""
  );

  const getInvoices = async (url) => {
    await axios
      .request({
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      })
      .then((response) => {
        setInvoices(response.data);
        setInvoiceFilter(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    let isFinanza = userData.user.rol.includes("Finanzas");
    let isContabilidad = userData.user.rol.includes("Conciliaciones");
    let isContact = contacts.includes(userData.user.email);

    const contact =
      isContact && !isContabilidad && !isFinanza
        ? "&search=" + userData.user.email + "&representative=" + false
        : "";

    setUrl("facturas/?states=1&states=2&states=3&states=4" + contact);

    toast.promise(
      getInvoices("facturas/?states=1&states=2&states=3&states=4" + contact),
      {
        pending: "Cargando Facturas...",
        success: "Carga Exitosa.",
        error: "Error al cargar.",
      }
    );
  }, []);

  const filterStates = (e) => {
    let requestString = "facturas/?";
    let request = "";

    if (e.length !== 0) {
      e.forEach((state) => {
        requestString += "states=" + state.value + "&";
        request = requestString.slice(0, -1);
      });

      if (isContact && !isContabilidad && !isFinanza) {
        request +=
          "&search=" + userData.user.email + "&representative=" + false;
      }

      setUrl(request);
    } else {
      setUrl("facturas/");
    }
  };

  const handleFilter = () => {
    let request = url
      .replace("&empresa=LBO%20S.A", "")
      .replace("&empresa=Agrofidi", "");

    request += checkedLBO
      ? checkedAgrofidi
        ? ""
        : "&empresa=LBO%20S.A"
      : checkedAgrofidi
      ? "&empresa=Agrofidi"
      : "";

    if (DateDesde !== null && DateHasta !== null) {
      let desde = new Date(DateDesde)
        .toLocaleString()
        .split(",")[0]
        .replaceAll("/", "-");

      let Hasta = new Date(DateHasta)
        .toLocaleString()
        .split(",")[0]
        .replaceAll("/", "-");

      request += "&datefrom=" + desde + "&dateto=" + Hasta;
    }

    if (isRepresentative) {
      request += "&representative=" + isRepresentative;
    }

    if (isContact && !isContabilidad && !isFinanza) {
      request += "&search=" + userData.user.email + "&representative=" + false;
    }

    getInvoices(request);
  };

  return (
    <div>
      <Sidebars></Sidebars>
      <div className="boxstaff">
        <h2 className="titlestaff">Lista de Facturas</h2>

        <p
          className="cbustaff"
          style={{
            "z-index": 1,
            display: "flex",
            width: "90%",
            "justify-content": "space-between",
          }}
        >
          <FilterSelect filterStates={filterStates}></FilterSelect>
          <p>
            <Search
              urlBase={url}
              setFilter={setInvoiceFilter}
              labelInput={"prov/cuil/Autorizante/Solicitante"}
            ></Search>
          </p>
        </p>
        <p className="cbustaff" style={{ "z-index": 0 }}>
          <FilterCalendar
            label={"Desde"}
            filterDate={DateDesde}
            setFilterDate={setDateDesde}
          ></FilterCalendar>
          {"   "}
          <FilterCalendar
            label={"Hasta"}
            filterDate={DateHasta}
            setFilterDate={setDateHasta}
          ></FilterCalendar>
        </p>
        <div className="rowStaff">
          <p className="">
            <button className="button-filter" onClick={handleFilter}>
              Filtrar
            </button>
          </p>
          {isFinanza ? (
            <>
              <p className="">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="success"
                      checked={isRepresentative}
                      onChange={(e) => {
                        setIsRepresentative(e.target.checked);
                      }}
                    />
                  }
                  label="Solo representantes"
                ></FormControlLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="success"
                      checked={checkedLBO}
                      onChange={(e) => {
                        setCheckedLBO(e.target.checked);
                      }}
                    />
                  }
                  label="LBO S.A"
                ></FormControlLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="success"
                      checked={checkedAgrofidi}
                      onChange={(e) => setCheckedAgrofidi(e.target.checked)}
                    />
                  }
                  label="Agrofidi"
                ></FormControlLabel>

                <ModalTxt></ModalTxt>
                <ModalStates></ModalStates>
              </p>
            </>
          ) : (
            <>
              <p>
                {isContabilidad ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        color="success"
                        checked={isRepresentative}
                        onChange={(e) => {
                          setIsRepresentative(e.target.checked);
                        }}
                      />
                    }
                    label="Solo representantes"
                  ></FormControlLabel>
                ) : (
                  <></>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="success"
                      checked={checkedLBO}
                      onChange={(e) => {
                        setCheckedLBO(e.target.checked);
                      }}
                    />
                  }
                  label="LBO S.A"
                ></FormControlLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="success"
                      checked={checkedAgrofidi}
                      onChange={(e) => setCheckedAgrofidi(e.target.checked)}
                    />
                  }
                  label="Agrofidi"
                ></FormControlLabel>
              </p>
            </>
          )}
        </div>
        <Row>
          <Col>
            <div className="table-responsive divstaff">
              <table className="table table-sm table-striped table-hover table-bordered table2staff">
                <thead>
                  <tr>
                    <th>R</th>
                    <th>Empresa</th>
                    <th>Fecha de carga</th>
                    <th>Fecha de Pago</th>
                    <th>Proveedor</th>
                    <th>CBU</th>
                    <th>Tipo</th>
                    <th>Concepto</th>
                    <th>Monto</th>
                    <th>Retenciones</th>
                    <th>Neto</th>
                    <th>
                      {"Autorizante/"} <br /> {"Solicitante"}
                    </th>
                    <th>
                      {" "}
                      {"Factura/"} <br /> {"Editar"}
                    </th>
                    <th>Observación</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {invoicesFilter.map((invoice) => {
                    return (
                      <DataTable
                        toast={toast}
                        invoice={invoice}
                        key={invoice.id}
                      ></DataTable>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import "./sidebars.css";
import atomIconMenu from "../../assets/atomIconMenu.svg";
import atomLogoLBO from "../../assets/atomLogoLBO.svg";
import close from "../../assets/close.svg";
import home from "../../assets/home.svg";
import factura from "../../assets/factura.svg";
import Vector from "../../assets/Vector.svg";
import Items from "./Items";
import salir from "../../assets/salir.svg";
import modeportrait from "../../assets/mode-portrait.svg";
import { useNavigate } from "react-router-dom";
import { useEncryptedData } from "../../utils/encryptedData";
import { Link } from "react-router-dom";
import { logout } from "../../utils/logout";
import { contacts } from "../../utils/listContacs";

const Sidebars = () => {
  const ls = useEncryptedData();
  const userData = ls.get("token", { secret: process.env.REACT_APP_SECRET });
  const navigate = useNavigate();

  let listData = [];

  let isStaff = false;
  let isFinanza = false;
  let isContact = false;
  let isContabilidad = false;

  if (userData !== null && userData !== undefined) {
    isStaff = userData.user.rol.includes("Staff LBO");
    isFinanza = userData.user.rol.includes("Finanzas");
    isContact = contacts.includes(userData.user.email);
    isContabilidad = userData.user.rol.includes("Conciliaciones");
  }

  if (isStaff && !(isFinanza || isContabilidad || isContact)) {
    listData = [
      {
        title: "Inicio",
        path: "/",
        icon: <img src={home} alt="inicio" width="30" height="30"></img>
      },
      {
        title: "Subir Facturas",
        path: "/Staff/subirfacturas",
        icon: (
          <img src={factura} alt="subir factura" width="30" height="30"></img>
        )
      },
      {
        title: "Ver Facturas",
        path: `/misfacturas/${userData.user.cuil}`,
        icon: <img src={Vector} alt="ver factura" width="30" height="30"></img>
      },
      {
        title: "Proveedores",
        path: "/proveedores",
        icon: (
          <img
            src={modeportrait}
            alt="Alta de proveedor"
            width="30"
            height="30"></img>
        )
      }
    ];
  } else if (isFinanza || isContabilidad || isContact) {
    listData = [
      {
        title: "Inicio",
        path: "/",
        icon: <img src={home} alt="inicio" width="30" height="30"></img>
      },
      {
        title: "Subir Facturas",
        path: "/Staff/subirfacturas",
        icon: (
          <img src={factura} alt="subir factura" width="30" height="30"></img>
        )
      },
      {
        title: "Ver Facturas",
        path: "/Staff/facturas",
        icon: <img src={Vector} alt="ver factura" width="30" height="30"></img>
      },
      {
        title: "Proveedores",
        path: "/proveedores",
        icon: (
          <img
            src={modeportrait}
            alt="Alta de proveedor"
            width="30"
            height="30"></img>
        )
      }
    ];
  } else if (userData !== null && userData !== undefined) {
    listData = [
      {
        title: "Inicio",
        path: "/",
        icon: <img src={home} alt="inicio" width="30" height="30"></img>
      },
      {
        title: "Subir mis Facturas",
        path: "/subirfacturas",
        icon: (
          <img src={factura} alt="subir factura" width="30" height="30"></img>
        )
      },
      {
        title: "Ver mis Facturas",
        path: `/misfacturas/${userData.user.cuil}`,
        icon: <img src={Vector} alt="ver factura" width="30" height="30"></img>
      }
    ];
  }

  const atomIcon = atomIconMenu;
  const atomLogo = atomLogoLBO;
  const atomClose = close;
  const out = salir;

  const [sidebar, setSidebars] = useState(false);

  let isLogin = userData !== null && userData !== undefined ? true : false;

  return (
    <>
      <div className="Nav">
        <div className="NavIcon">
          {isLogin ? (
            <button className="button-16" onClick={() => setSidebars(!sidebar)}>
              {sidebar ? (
                <img src={atomClose} alt="close" width="20" height="20" />
              ) : (
                <img src={atomIcon} alt="barra" width="20" height="20" />
              )}
            </button>
          ) : (
            <p></p>
          )}
          <div className="center">
            <Link to={"/"}>
              <img src={atomLogo} alt="logo" width="60" height="60"></img>
            </Link>
          </div>
          {isLogin ? (
            <button
              className="button-16"
              onClick={(e) => {
                localStorage.removeItem("token");
                logout(e);
                isLogin = false;
                navigate("/login");
              }}>
              <img
                className="margin"
                src={out}
                alt="barra"
                width="20"
                height="20"
              />
            </button>
          ) : (
            <p></p>
          )}
        </div>
      </div>
      {sidebar ? (
        <div className="SidebarNav">
          <div className="SidebarWrap">
            {listData.map((item, index) => {
              return <Items item={item} key={index}></Items>;
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Sidebars;

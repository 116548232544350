import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import add from '../../assets/add proveedor.svg';
import { listColumsProviders } from '../../utils/ColumnsProviders';
import { useEncryptedData } from '../../utils/encryptedData';
import axios from '../api/axios';
import Search from '../search/Search';
import Sidebars from '../sidebars/sidebars';

const TableProveedores = () => {
  const ls = useEncryptedData();
  const userData = ls.get('token', {
    secret: process.env.REACT_APP_SECRET,
  });
  let isFinanza = false;
  let isContabilidad = false;

  if (userData !== null && userData !== undefined) {
    isFinanza = userData.user.rol.includes('Finanzas');
    isContabilidad = userData.user.rol.includes('Conciliaciones');
  }

  const [pending, setPending] = useState(true);

  const [listProv, setListProv] = useState([]);

  const getListProv = async () => {
    const optionsProv = {
      method: 'GET',
      url: 'proveedores/'
    };

    await axios
      .request(optionsProv)
      .then((response) => {
        setPending(false);
        setListProv(response.data);
      })
      .catch((error) => {
        toast.error('Error al cargar proveedores');
      });
  };

  const columns = listColumsProviders(isFinanza, isContabilidad);

  const ExpandedComponent = ({ data }) => (
    <pre
      style={{
        'font-family': 'IBM Plex Sans',
        margin: '0% 5% 0% 5%',
        display: 'flex',
        'flex-direction': 'column',
        'flex-wrap': 'wrap',
        background: 'rgba(217, 217, 217, 1)',
      }}
    >
      <div className="mb-3 rowform">
        <label
          className="col-sm-1 col-form-label"
          style={{ 'margin-left': '2%' }}
        >
          <b>nombre:</b>
        </label>
        <div className="col-sm-1">
          <p className="form-control-plaintext">{data.name}</p>
        </div>
        <label
          className="col-sm-1 col-form-label"
          style={{ 'margin-left': '30%' }}
        >
          <b>email:</b>
        </label>
        <div className="col-sm-1">
          <p className="form-control-plaintext">{data.email}</p>
        </div>
      </div>
      <div className="mb-3 rowform">
        <label
          className="col-sm-1 col-form-label"
          style={{ 'margin-left': '2%' }}
        >
          <b>cuil:</b>
        </label>
        <div className="col-sm-1">
          <p className="form-control-plaintext">{data.cuil}</p>
        </div>
        <label
          className="col-sm-1 col-form-label"
          style={{ 'margin-left': '30%' }}
        >
          <b>CBU:</b>
        </label>
        <div className="col-sm-1">
          <p className="form-control-plaintext">{data.cbu}</p>
        </div>
      </div>
      <div className="mb-3 rowform">
        <label
          className="col-sm-1 col-form-label"
          style={{ 'margin-left': '2%' }}
        >
          <b>área:</b>
        </label>
        <div className="col-sm-1">
          <p className="form-control-plaintext">{data.area}</p>
        </div>
        <label
          className="col-sm-1 col-form-label"
          style={{ 'margin-left': '30%' }}
        >
          <b>tipo:</b>
        </label>
        <div className="col-sm-1">
          <p className="form-control-plaintext">{data.usertype}</p>
        </div>
      </div>
      <div className="mb-3 rowform">
        <label
          className="col-sm-1 col-form-label"
          style={{ 'margin-left': '2%' }}
        >
          <b>creado:</b>
        </label>
        <div className="col-sm-1">
          <p className="form-control-plaintext">
            {data.creation_date.split('T')[0]}
          </p>
        </div>
        <label
          className="col-sm-1 col-form-label"
          style={{ 'margin-left': '30%' }}
        >
          <b>estado:</b>
        </label>
        <div className="col-sm-1">
          <p
            className={
              data.state === '1'
                ? 'text-success form-control-plaintext'
                : 'text-danger form-control-plaintext'
            }
          >
            {data.state === '1' ? 'Activo' : 'Inactivo'}
          </p>
        </div>
      </div>
      <div className="mb-3 rowform">
        <label
          className="col-sm-1 col-form-label"
          style={{ 'margin-left': '2%' }}
        >
          <b>CBU USD:</b>
        </label>
        <div className="col-sm-1">
          <p className="form-control-plaintext">
            {data.cbu_usd ? data.cbu_usd : 'no tiene'}
          </p>
        </div>
      </div>
    </pre>
  );

  useEffect(() => {
    getListProv();
  }, []);

  const updateListProv = (newListProv) => {
    setListProv(newListProv);
  };

  return (
    <>
      <Sidebars></Sidebars>
      <div
        style={{
          width: '80%',
          margin: 'auto',
        }}
      >
        <br></br>
        <h3 className="titleform">
          <Link to={'/proveedores/alta'}>
            <button type="button" class="btn btn-light">
              <img
                src={add}
                alt="cargar proveedor"
                height={40}
                width={40}
              ></img>
            </button>
          </Link>
          <div
          style={{
            'margin-top': '2%',
            'margin-bottom': '2%',
          }}
        >
          <Search
            urlBase={'proveedores/'}
            setFilter={updateListProv}
            labelInput={'prov/cuil/email'}
          ></Search>
        </div>
        </h3>
     
        <div className="card text-sm-light mb-3 div-header-info">
          <div className="card-body">
            <DataTable
              title="Tabla Proveedores"
              columns={columns}
              data={listProv}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              expandOnRowClicked
              expandOnRowDoubleClicked
              highlightOnHove
              direction="auto"
              pagination
              responsive
              progressPending={pending}
              highlightOnHover
            ></DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableProveedores;
